import React, { useContext, useState } from "react";

import "./css/login.css";

import UserContext from "../context/UserContext";

import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const user_context = useContext(UserContext);
  const { userData, setLoginData, setUserData } = user_context;

  const [LoginCred, setLoginCred] = useState({ email: "", password: "" });

  const attemptLogin = async (tc) => {
    // console.log(tc);
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tc),
      }
    );

    const json = await response.json();
    return json;
  };
  const onChangeInput = (e) => {
    console.log(e.target.name + ":" + e.target.value.replace(" ", ""));
    setLoginCred({
      ...LoginCred,
      [e.target.name]: e.target.value.replace(" ", ""),
    });
  };
  const onFormSubmit = async (e) => {
    e.preventDefault();
    console.log(LoginCred);
    const checkdata = await attemptLogin(LoginCred);
    setLoginData(checkdata);

    if (checkdata.status) {
      setUserData(checkdata);
      window.localStorage.setItem("LoginData", JSON.stringify(checkdata));
      // alert("Successfull");
      navigate("/dashboard");
    } else {
      alert("Wrong User ID/Pass");
    }
  };
  return (
    <div className="global-container">
      <div className="card login-form p-3 rounded">
        <div className="card-body text-center">
          <img
            className="mb-3"
            src="https://swastikclasses.com/START/images/light-logo-1.0.png"
            width="70%"
            alt="Swastik Classes Logo"
          ></img>
          <h1 className="card-title text-center fw-bold">AIOTS Login</h1>
          <div className="card-text">
            <form>
              {/* <!-- to error: add className "has-danger" --> */}
              <div className="form-group text-start">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  className="form-control form-control-sm"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={onChangeInput}
                />
              </div>
              <div className="form-group text-start mt-3">
                <label htmlFor="exampleInputPassword1">Password</label>
                <a href="#" style={{ float: "right", fontSize: "12px" }}>
                  Forgot password?
                </a>
                <input
                  type="password"
                  name="password"
                  onChange={onChangeInput}
                  placeholder="Password"
                  className="form-control form-control-sm"
                  id="exampleInputPassword1"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block w-100"
                onClick={onFormSubmit}
              >
                Sign in
              </button>

              <div className="sign-up">
                Don't have an account? <a href="/createuser">Create One</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;

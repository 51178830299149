// eslint-disable-next-line
import React from "react";

const Navbar = (props) => {
  let { user } = props;
  user = user.data;

  // console.log("hello ", user);
  return (
    <nav className="navbar navbar-expand-lg bg-light ">
      <div className="col-8 text-start p-3">
        <img
          src="https://swastikclasses.com/START/images/light-logo-1.0.png"
          width="20%"
          alt="Swastik Classes Logo"
        ></img>
      </div>

      <div className="col-2 text-end">
        <div>
          <div className="row">
            <div className="col-3">
              <img
                src="https://cdn-icons-png.flaticon.com/512/6828/6828682.png"
                width="100px"
                alt="Wallet Icon"
              ></img>
            </div>
            <div className="col-9 text-start">
              <small>Earning:</small>
              <br />
              <b>{user.lte && user.lte ? user.lte[0].earning : 0} Credits</b>
            </div>
          </div>
        </div>
      </div>

      <div className="col-2 container text-left">
        <ul className="navbar-nav">
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <b>{user[0] && user[0].name ? user[0].name : ""}</b>
              {/* <b>{user.name}</b> */}
              <br />
              <small>{user[0] && user[0].email ? user[0].email : ""}</small>
              {/* <small>{user.email}</small> */}
            </a>
            <ul className="dropdown-menu">
              {/* <li>
                <a className="dropdown-item" href="#">
                  {user.phone}
                </a>
              </li> */}
              {/* <li>
                <hr className="dropdown-divider" />
              </li> */}
              <li
                onClick={() => {
                  window.localStorage.setItem("LoginData", "");
                  window.location.replace("/login");
                }}
              >
                <a className="dropdown-item" href="#">
                  Logout
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;

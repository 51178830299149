import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const BuyBTNModal = (props) => {
  const { id, user_data } = props;
  const [show, setShow] = useState(false);
  const [creds, setCredsData] = useState({ email_id: "", password: "" });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  var userData = user_data[0];

  const buy_credit = (amt) => {
    console.log(
      `${process.env.REACT_APP_API_URL}/paytm/paynow?email=${userData.email}&amount=${amt}&phone=${userData.phone}`
    );
    window.location.href = `${process.env.REACT_APP_API_URL}/paytm/paynow?email=${userData.email}&amount=${amt}&phone=${userData.phone}`;
  };

  return (
    <>
      <Button
        className="btn btn-success w-50"
        data-bs-toggle="modal"
        onClick={handleShow}
      >
        Buy Credits
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buy Credits</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table table-striped table-hover p-5 border rounded">
            <thead>
              <tr>
                <th>Credits</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  200 Credits<br></br>
                  <small>Rs. 199</small>
                </td>
                <td>
                  <button
                    className="btn-success btn mt-2 mb-3"
                    onClick={() => buy_credit(199)}
                  >
                    Buy Credits
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  1000 Credits<br></br>
                  <small>Rs. 699</small>
                </td>
                <td>
                  <button
                    className="btn-success btn mt-2 mb-3"
                    onClick={() => buy_credit(699)}
                  >
                    Buy Credits
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  2000 Credits<br></br>
                  <small>Rs. 999</small>
                </td>
                <td>
                  <button
                    className="btn-success btn mt-2 mb-3"
                    onClick={() => buy_credit(999)}
                  >
                    Buy Credits
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyBTNModal;

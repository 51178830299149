import React, { useContext } from "react";
import UserContext from "../context/UserContext";
import BuyNowModal from "./BuyNowModal";
import CredentialModal from "./CredentialModal";
import TestDetailModal from "./TestDetailModal";

const UpcomingTest = (props) => {
  const user_context = useContext(UserContext);
  const { aiotsData, email } = user_context;
  console.log(aiotsData);

  const test_data = aiotsData.data;

  return (
    <div className="border bg-light rounded">
      <div className="text-center m-4 mb-0 p-2 fw-bold">
        <h1 className="fw-bold">Upcoming Tests</h1>
      </div>
      <div className="p-1 m-3">
        {test_data.upcomingTest ? (
          test_data.upcomingTest.map((e, idx) => {
            return test_template(e, idx, email);
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

function test_template(test, idx, email) {
  return (
    <div className="row border m-2 p-2 rounded bg-white" key={idx}>
      <div className="col-md-8 align-middle ">
        <strong>{test.TestName}</strong>
        <br></br>
        <span>
          {test.time_of_test} |{" "}
          <TestDetailModal
            date={test.time_of_test}
            time={test.time}
            syllabus={test.syllabus}
          />
        </span>
      </div>
      <div className="col-md-4 fw-bold text-center">
        {console.log(test.user.status)}
        {test.user.status ? (
          <CredentialModal id={test.user.cred_id} />
        ) : (
          <BuyNowModal test={test} email={email} />
        )}
      </div>
    </div>
  );
  //   if (txn.type === "credit") {
  //   } else if (txn.type === "AIOTS") {
  //     return (
  //       <div className="row">
  //         <div className="col-8 ">
  //           {txn.txn_date}
  //           <br />
  //           <small>
  //             <i>({txn.order_id})</i>
  //           </small>
  //         </div>
  //         <div className="col-4 fw-bold">
  //           <img
  //             alt="-"
  //             src="https://img.icons8.com/color/48/null/minus.png"
  //             width="20px"
  //           />
  //           C{txn.txn_amount}
  //         </div>
  //       </div>
  //     );
  //   }
}

export default UpcomingTest;

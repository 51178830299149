import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";

const TestDetailModal = (props) => {
  const { date, time, syllabus } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <a href="#" className="" data-bs-toggle="modal" onClick={handleShow}>
        More Details
      </a>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Test Details</Modal.Title>x
        </Modal.Header>
        <Modal.Body>
          <table class="table table-bordered text-center">
            <tbody>
              <tr>
                <th scope="row">Date</th>
                <td>{date}</td>
              </tr>
              <tr>
                <th scope="row">Time</th>
                <td>{time}</td>
              </tr>
              <tr>
                <th scope="row">Syllabus</th>
                <td className="text-start">{syllabus}</td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TestDetailModal;

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";

const BuyNowModal = (props) => {
  const { test, email } = props;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const buyTest = async (test_id) => {
    let status = await buyTestconfirm(test.id, test.TestName, email);

    handleClose();
    if (status.status == true) {
      window.location.reload();
    } else {
      alert(status.error);
    }
  };

  const handleShow = () => setShow(true);

  const buyTestconfirm = async (test_id, test_name, email) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/tests/buy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        test_id,
        test_name,
        email,
      }),
    });

    const json = await response.json();
    // setUserData({ ...json });

    return json;
  };

  return (
    <>
      <Button
        className="btn btn-success w-100"
        data-bs-toggle="modal"
        onClick={handleShow}
      >
        Buy Now
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Buy {test.TestName}</Modal.Title>x
        </Modal.Header>
        <Modal.Body>
          Do you want to purchase this AIOTS({test.TestName})?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={() => buyTest(test.test_id)}>
            Buy Now
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BuyNowModal;

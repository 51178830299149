import React, { useState } from "react";
import UserContext from "./UserContext";

const UserState = (props) => {
  const [LoginData, setLoginData] = useState({
    success: false,
    data: {},
    transaction: [],
  });
  const [userData, setUserData] = useState({
    success: false,
    data: {},
    transaction: [],
    successfulReferals: [],
  });
  const [aiotsData, setAiotsData] = useState({
    status: true,

    data: {
      upcomingTest: [],

      pastTest: [],
    },
  });

  const local_userData = window.localStorage.getItem("LoginData")
    ? JSON.parse(window.localStorage.getItem("LoginData"))
    : "";

  const email = local_userData != "" ? local_userData.data.email : "";

  return (
    <UserContext.Provider
      value={{
        LoginData,
        setLoginData,
        userData,
        setUserData,
        aiotsData,
        setAiotsData,
        email,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserState;

import React, { useContext, useEffect } from "react";
import CreditBox from "../components/CreditBox";
import Navbar from "../components/Navbar";
import PastTest from "../components/PastTest";
import ReferalBox from "../components/ReferalBox";
import UpcomingTest from "../components/UpcomingTest";

import UserContext from "../context/UserContext";

const Dashboard = () => {
  const user_context = useContext(UserContext);
  const { userData, setUserData, aiotsData, setAiotsData } = user_context;

  const fetchUserData = async (email) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/user/getData?email=${email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();
    // console.log(json);
    setUserData({ ...json });

    if (json.status === false) {
      alert("User Not Found");
      window.location.replace("/login");
    }

    return json;
  };

  const fetchTestData = async (email) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tests/getData?email=${email}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();
    // console.log(json);
    setAiotsData({ ...json });

    if (json.status === false) {
      alert("User Not Found");
      window.location.replace("/login");
    }

    return json;
  };

  useEffect(() => {
    if (window.localStorage.getItem("LoginData") == "") {
      window.location.replace("/");
    }
    const local_userData = JSON.parse(window.localStorage.getItem("LoginData"));
    let email = local_userData.data.email;
    fetchTestData(email);
    fetchUserData(email);
    // return () => {
    //   console.log("fetching Test and Userdata");
    // };
  }, []);

  return (
    <>
      <Navbar user={userData} />
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-8 mt-2">
            <UpcomingTest test_data={aiotsData} />
          </div>
          <div className="col-md-4 mt-2">
            <CreditBox user_data={userData} />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-8 mt-2">
            <PastTest test_data={aiotsData} />
          </div>
          <div className="col-md-4 mt-2">
            <ReferalBox user_data={userData} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;

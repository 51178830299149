import React from "react";

const ReferalBox = (props) => {
  let user_data = props.user_data;
  user_data = user_data.data;

  return (
    <div className="text-center p-4 rounded border">
      <h3 className="h3 mb-0">Your Referal Code:</h3>
      <h2 className="h2 mt-0">
        <strong>
          {user_data[0] && user_data[0].referal_code
            ? user_data[0].referal_code
            : ""}
        </strong>
      </h2>
      <p>
        Share your referal code with others and earn{" "}
        <strong>50 Credits </strong>
        per registration, and <strong>150 Credits</strong> when they buy's their
        2nd test.
      </p>

      <div className="text-small text-start">
        {user_data.successfulReferals != null ? (
          user_data.successfulReferals.map((e, idx) => {
            return successful_referals(e, idx);
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

function successful_referals(txn, idx) {
  // console.log(txn);
  return (
    <div className="row align-middle" key={idx}>
      <div className="col-8">
        {txn.userName}{" "}
        <b>
          {txn.type === "reg"
            ? !txn.order_id.includes("Signup")
              ? "REG"
              : ""
            : txn.testName}{" "}
          <small>{txn.order_id.split("REG")[0]}</small>
        </b>
        <br />
        <small>
          <i>({txn.timestampt.split("T")[0]})</i>
        </small>
      </div>
      <div className="col-4 fw-bold">
        <img
          alt="+"
          src="https://img.icons8.com/color/48/null/plus--v1.png"
          width="20px"
        />
        {txn.txn_amount} C
      </div>
    </div>
  );
}

export default ReferalBox;

import "./App.css";

import { Routes, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import CreateUser from "./pages/CreateUser";
// import { Offcanvas } from "bootstrap";

function App() {
  // document.addEventListener("DOMContentLoaded", function () {
  //   const bsOffcanvas = new Offcanvas("#offcanvasNav");
  // });

  //console.log(process.env.REACT_APP_API_URL);
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/createuser" element={<CreateUser />} />
      {/* <Route
        path="/addTC"
        element={LoginData.status ? <NewTC /> : <Navigate to="/login" />}
      /> */}
      <Route path="*" element={<h1>OOPS Not Found</h1>} />
    </Routes>
  );
}

export default App;

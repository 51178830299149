import React, { useContext } from "react";
import TestDetailModal from "./TestDetailModal";
import UserContext from "../context/UserContext";

const PastTest = () => {
  const user_context = useContext(UserContext);
  const { aiotsData } = user_context;

  const test_data = aiotsData.data;

  return (
    <div className="border bg-light rounded">
      <div className="text-center m-4 mb-0 p-2 fw-bold">
        <h1 className="fw-bold">Past Tests</h1>
      </div>
      <div className="p-1 m-3">
        {test_data.pastTest ? (
          test_data.pastTest.map((e, idx) => {
            return test_template(e, idx);
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
function test_template(test, idx) {
  return (
    <div className="row border m-2 p-2 rounded bg-white" key={idx}>
      <div className="col-md-8 align-middle">
        <strong>{test.TestName}</strong>
        <br></br>
        <span>
          {test.time_of_test} |{" "}
          <TestDetailModal
            date={test.time_of_test}
            time={test.time}
            syllabus={test.syllabus}
          />
        </span>
      </div>
      <div className="col-md-4 fw-bold text-center">
        <button
          className="btn btn-info w-100"
          onClick={() => {
            window.open(test.leaderboard_link, "_blank");
          }}
        >
          Leaderboard
        </button>
      </div>
    </div>
  );
}

export default PastTest;

import React, { useContext, useState } from "react";
import UserContext from "../context/UserContext";

const CreateUser = () => {
  const user_context = useContext(UserContext);
  const { setLoginData } = user_context;

  const [LoginCred, setLoginCred] = useState({ email: "", password: "" });

  const [userOtp, setUserOtp] = useState({ status: false, value: "" });

  const sendOTP = async (e) => {
    e.preventDefault();
    let otp_val = Math.floor(Math.random() * 100000 + 1);
    setUserOtp({ status: true, value: otp_val });
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/otp?phone_number=${LoginCred.phone}&phone_number=${LoginCred.phone}&authKey=Swastik_AuthKey_1.0&otp_6=${otp_val}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://localhost:3000",
          "Access-Control-Allow-Credentials": "true",
        },
      }
    );

    const json = await response.json();
  };

  const createUser = async (tc) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/user/add`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tc),
    });

    const json = await response.json();
    return json;
  };

  const onChangeInput = (e) => {
    setLoginCred({
      ...LoginCred,
      [e.target.name]: e.target.value.replace(" ", ""),
    });
  };

  const onFormSubmit = async (e) => {
    e.preventDefault();
    if (parseInt(LoginCred.otp) === userOtp.value) {
      const checkdata = await createUser(LoginCred);
      setLoginData(checkdata);
      setLoginCred({
        email: "",
        password: "",
      });
      if (checkdata.status) {
        window.localStorage.setItem("LoginData", JSON.stringify(checkdata));
        alert("Successfull");

        window.location.replace("/");
      } else if (
        checkdata.status == false &&
        checkdata.error.code == "ER_DUP_ENTRY"
      ) {
        alert("User Already Exists, Duplicate Email/Phone Number");
      } else {
        alert("Wrong User ID/Pass");
      }
    } else {
      alert("Wrong OTP");
    }
  };

  return (
    <div className="global-container">
      <div className="card login-form p-3 rounded">
        <div className="card-body text-center">
          <img
            className="mb-3"
            src="https://swastikclasses.com/START/images/light-logo-1.0.png"
            width="70%"
            alt="Swastik Classes Logo"
          ></img>
          <h1 className="card-title text-center fw-bold fs-5">
            AIOTS Create User
          </h1>
          <div className="card-text">
            <form>
              <div className="form-group text-start mt-2">
                <label for="exampleInputEmail1">Full Name</label>
                <input
                  className="form-control form-control-md"
                  id="exampleInputName"
                  aria-describedby="nameHelp"
                  type="text"
                  name="name"
                  placeholder="Full Name"
                  onChange={onChangeInput}
                />
              </div>
              <div className="form-group text-start mt-3">
                <div className="row">
                  <div className="col-7">
                    <label for="phoneNumber">Phone Number</label>
                    <input
                      className="form-control form-control-md "
                      id="phoneNumber"
                      aria-describedby="phoneHelp"
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                      onChange={onChangeInput}
                    />
                  </div>
                  <div className="col-5">
                    <button
                      className="btn btn-primary px-2"
                      onClick={(e) => sendOTP(e)}
                    >
                      Send OTP
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={
                  userOtp.status === false
                    ? "d-none"
                    : "form-group text-start mt-3"
                }
              >
                <label for="exampleInputEmail1">OTP</label>
                <input
                  className="form-control form-control-md"
                  id="exampleInputPhone"
                  aria-describedby="phoneHelp"
                  type="text"
                  name="otp"
                  placeholder="Enter 6 Digit OTP"
                  onChange={onChangeInput}
                />
              </div>

              <div className="form-group text-start mt-3">
                <label for="exampleInputEmail1">Email address</label>
                <input
                  className="form-control form-control-md"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  type="email"
                  name="email"
                  placeholder="Email"
                  onChange={onChangeInput}
                />
              </div>
              <div className="form-group text-start mt-3">
                <label for="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  name="password"
                  onChange={onChangeInput}
                  placeholder="Password"
                  className="form-control form-control-md"
                  id="exampleInputPassword1"
                />
              </div>
              <div className="form-group text-start mt-3">
                <label for="referalcode">Referal Code</label>
                <input
                  type="text"
                  name="referee"
                  onChange={onChangeInput}
                  placeholder="Referal Code"
                  className="form-control form-control-md"
                  id="referalcode"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary btn-block w-100"
                onClick={onFormSubmit}
                disabled={userOtp.status === false ? "true" : ""}
              >
                Sign in
              </button>

              <div className="sign-up">
                Already have an account? <a href="/login">Login</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateUser;

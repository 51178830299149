import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const CredentialModal = (props) => {
  const { id } = props;
  const [show, setShow] = useState(false);
  const [creds, setCredsData] = useState({ email_id: "", password: "" });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchCredsData = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tests/getCred/?id=${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();
    setCredsData({ ...json.data });

    if (json.status == false) {
      alert("Some Error");
      window.location.replace("/login");
    }

    return json;
  };

  useEffect(() => {
    fetchCredsData(id);
    return async () => {
      console.log("Credential Fetched");
    };
  }, []);

  return (
    <>
      <Button
        className="btn btn-info w-100"
        data-bs-toggle="modal"
        onClick={handleShow}
      >
        Check Credentials
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Credentials for {}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table class="table table-striped table-hover p-5 border rounded">
            <thead>
              <tr>
                <th>User ID</th>
                <th>Password</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{creds.email_id}</td>
                <td>{creds.password}</td>
              </tr>
            </tbody>
          </table>
            <p>Test URL: <a href="https://test.swastik.live" target="_blank">test.swastik.live</a></p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CredentialModal;

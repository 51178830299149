import React from "react";
import { useNavigate, redirect } from "react-router-dom";
import BuyBTNModal from "./BuyBTNModal";

const CreditBox = (props) => {
  const navigate = useNavigate();
  let user_data = props.user_data;
  user_data = user_data.data;

  return (
    <div className="text-center p-4 rounded border">
      <h2>
        Credits Balance :
        <strong>
          {user_data.wallet ? user_data.wallet[0].credits : 0} Credits
        </strong>
      </h2>
      <BuyBTNModal user_data={user_data} />
      <div className="text-small text-start">
        {user_data.transaction != null ? (
          user_data.transaction.map((e, idx) => {
            return transaction_template(e, idx);
          })
        ) : (
          <>{}</>
        )}
      </div>
    </div>
  );
};

function transaction_template(txn, idx) {
  if (txn.type === "credit") {
    return (
      <div className="row align-middle" key={idx}>
        <div className="col-8">
          <b>{txn.txn_date.split("T")[0]}</b>
          <br />
          <small>
            <i>({txn.order_id})</i>
          </small>
        </div>
        <div className="col-4">
          <img
            alt="+"
            src="https://img.icons8.com/color/48/null/plus--v1.png"
            width="20px"
          />
          {parseInt(txn.txn_amount) + " C"}
        </div>
      </div>
    );
  } else if (txn.type === "AIOTS") {
    return (
      <div className="row" key={idx}>
        <div className="col-8 ">
          <b>{txn.txn_date.split("T")[0]}</b>
          <br />
          <small>
            <i>({txn.order_id})</i>
          </small>
        </div>
        <div className="col-4 ">
          <img
            alt="-"
            src="https://img.icons8.com/color/48/null/minus.png"
            width="20px"
          />
          C{" " + txn.txn_amount}
        </div>
      </div>
    );
  }
}

export default CreditBox;
